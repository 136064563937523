<template>
  <div id="position_preferences">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_position_preferences = !show_position_preferences">
          <i v-if="show_position_preferences" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Position Preferences
          </span>
        </a>
      </div>
      <div>
        <span v-if="position_preferences.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_position_preferences">
        <div class="row">
          <div class="col-12">
            <p>This secure online application stores your data in your browser until you have pressed <badge pill type="primary">Submit</badge>
              or <badge pill type="danger">Clear My Data</badge> at the top of this application.</p>
            <p>If you are on a Public computer it is advised to complete the application all at once, clear your data
              if you do not complete or download and fill the application out by hand.</p>
            <p>HealthView Home Health &amp; Hospice is not responsible for data that has not been removed by pressing
              <badge pill type="primary">Submit</badge> or <badge pill type="danger">Clear My Data</badge> at the top
              of this application.</p>
          </div>
        </div>
        <hr>
        <!-- <pre v-html="position_preferences"></pre> -->
        <div class="row">
          <div class="col-12 col-md-6">
            <!-- TODO: Computed from Personal Data -->
            <base-input label="Applicant Name" v-model="position_preferences.name" placeholder="Your Name" v-validate="'required|alpha_spaces'"
              data-vv-name="position_preferences.name" data-vv-as="Name" :error="errors.first('position_preferences.name')"
              required @focus="formAltered"></base-input>
          </div>
          <div class="col-12 col-md-6">
            <base-input label="Applicant Email" v-model="position_preferences.email" placeholder="Your Email" v-validate="'email'"
              data-vv-name="position_preferences.email" data-vv-as="Email" :error="errors.first('position_preferences.email')"
 @focus="formAltered"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <div v-if="job_title">
                <label>Position</label>
                <input type="text" disabled v-model="position_preferences.position" v-validate="'required'"
                  data-vv-name="position_preferences.position" data-vv-as="Position" id="position" class="form-control"
                  required>
              </div>
              <div v-if="!job_title">
                <label>What position are you applying for?</label>
                <select v-model="position_preferences.position" v-validate="'required'" data-vv-name="position_preferences.position"
                  data-vv-as="Position" id="position" class="form-control" required @focus="formAltered">
                  <option v-for="i in positions" :value="i" :key="i" v-text="i"></option>
                </select>
                <input v-if="position_preferences.position === 'Other'" type="text" placeholder="Other postion..."
                  class="form-control mt-1" />
                <div class="text-danger invalid-feedback" style="display: block;" v-if="errors.first('position_preferences.position')">
                  {{ errors.first('position_preferences.position') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label class="form-check-label d-block">Schedule Desired*</label>

              <div class="text-danger invalid-feedback" style="display: block;" v-if="errors.first('position_preferences.schedule_desired')">
                {{ errors.first('position_preferences.schedule_desired') }}
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="position_preferences.schedule_desired" data-vv-as="Schedule Desired"
                  @focus="formAltered" class="form-check-input" type="radio" v-model="position_preferences.schedule_desired"
                  id="schedule_desired_full_time" value="Full Time">
                <label class="form-check-label" for="schedule_desired_full_time">Full Time</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="position_preferences.schedule_desired" data-vv-as="Schedule Desired"
                  @focus="formAltered" class="form-check-input" type="radio" v-model="position_preferences.schedule_desired"
                  id="schedule_desired_part_time" value="Part Time">
                <label class="form-check-label" for="schedule_desired_part_time">Part Time</label>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <base-input label="Number of hours per week" placeholder="40,30,..." v-model="position_preferences.hours"
              v-validate="'required|numeric'" data-vv-name="position_preferences.hours" data-vv-as="Hours" :error="errors.first('position_preferences.hours')"
              required @focus="formAltered"></base-input>
          </div>
        </div>
        <base-button v-if="position_preferences.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
        <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
      </section>
    </card>
  </div>
</template>
<script>
  export default {

    $_veeValidate: {
      validator: 'new'
      // v-model=""
      // v-validate="'required'"
      // data-vv-name=""
      // data-vv-as=""
      // :errors="errors.first('')"
      // required
    },
    props: {
      job_title: String,
      job_key: String,
      clear: String
    },
    watch: {
      clear() {
        this.position_preferences = {
          saved: false,
          section_complete: false,
          name: '',
          position: '',
          position_other: '',
          schedule_desired: [],
          hours: '',
          job_key: ''
        }
        this.$validator.reset()
      }
    },
    name: 'position_preferences',
    data: () => ({
      positions: [
        'RN', 'LPN', 'PT', 'PTA', 'OT', 'COTA', 'CNA', 'Other'
      ],
      show_position_preferences: true,
      position_preferences: {
        saved: false,
        section_complete: false,
        name: '',
        email: '',
        position: '',
        position_other: '',
        schedule_desired: [],
        hours: '',
        job_key: ''
      },
      dictionary: {
        custom: {
          schedule_desired: {
            length: 'Please select a desired schedule.'
            // custom messages
          },
        }
      }
    }),
    mounted() {
      this.$validator.localize('en', this.dictionary)
    },
    methods: {
      formAltered() {
        this.position_preferences.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.position_preferences')) {
          this.position_preferences = JSON.parse(localStorage.getItem('healthViewApplication.position_preferences'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.position_preferences.saved = true
            this.position_preferences.section_complete = false
            localStorage.setItem('healthViewApplication.position_preferences', JSON.stringify(this.position_preferences))
          } else {
            this.position_preferences.saved = true
            this.position_preferences.section_complete = true
            localStorage.setItem('healthViewApplication.position_preferences', JSON.stringify(this.position_preferences))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
      if (this.$route.query.job_title) {
        this.position_preferences.position = this.job_title
        this.position_preferences.job_key = this.job_key
      }

    }
  }
</script>
