<template>
  <div id="references">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_work_history = !show_work_history">
          <i v-if="show_work_history" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Work History
          </span>
        </a>
      </div>
      <div>
        <span v-if="work_history.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_work_history">
        <!-- Begin Current Employer -->
        <h4>Current or Recent Employer</h4>
        <section>
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.current_employer.employer" data-vv-as="Employer"
                :error="errors.first('work_history.current_employer.employer')" @focus="formAltered" required label="Employer"
                v-model="work_history.current_employer.employer"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.current_employer.city"
                data-vv-as="City" :error="errors.first('work_history.current_employer.city')" @focus="formAltered"
                required label="City" v-model="work_history.current_employer.city"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <label for="state">State*</label>
              <select v-validate="'required'" data-vv-name="work_history.current_employer.state" data-vv-as="State"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.current_employer.state">
                <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.current_employer.state')" class="text-danger invalid-feedback" style="display: block;">
                {{ errors.first('work_history.current_employer.state') }}
              </div>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="work_history.current_employer.phone"
                data-vv-as="Phone" :error="errors.first('work_history.current_employer.phone')" @focus="formAltered"
                required label="Phone" v-model="work_history.current_employer.phone"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.current_employer.title_position"
                data-vv-as="Title Position" :error="errors.first('work_history.current_employer.title_position')"
                @focus="formAltered" required label="Your title or position" v-model="work_history.current_employer.title_position"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.current_employer.supervisor_name"
                data-vv-as="Supervisor Name" :error="errors.first('work_history.current_employer.supervisor_name')"
                @focus="formAltered" required label="Supervisor/Manager Name" v-model="work_history.current_employer.supervisor_name"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <base-input v-validate="'required'" data-vv-name="work_history.current_employer.supervisor_title"
                data-vv-as="Supervisor Title" :error="errors.first('work_history.current_employer.supervisor_title')"
                @focus="formAltered" required label="Supervisor/Manager Title" v-model="work_history.current_employer.supervisor_title"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5 col-lg-7">
              <base-input v-validate="'required'" data-vv-name="work_history.current_employer.reason_for_leaving"
                data-vv-as="Reason for Leaving" :error="errors.first('work_history.current_employer.reason_for_leaving')"
                @focus="formAltered" required label="Reason for leaving" v-model="work_history.current_employer.reason_for_leaving"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required'" data-vv-name="work_history.current_employer.salary" data-vv-as="Salary"
                :error="errors.first('work_history.current_employer.salary')" @focus="formAltered" required label="Salary"
                v-model="work_history.current_employer.salary"></base-input>
            </div>
            <div class="col-12 col-md-3 col-lg-2">
              <label for="state">specify</label>
              <select v-validate="'required'" data-vv-name="work_history.current_employer.salary_type" data-vv-as="Salary Type"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.current_employer.salary_type">
                <option v-for="i in salary_types" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.current_employer.salary_type')" class="text-danger invalid-feedback"
                style="display: block;">
                {{ errors.first('work_history.current_employer.salary_type') }}
              </div>
            </div>
          </div>
          <h6>Dates for Employment</h6>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="work_history.current_employer.employment_start_date"
                data-vv-as="Date" :error="errors.first('work_history.current_employer.employment_start_date')" @focus="formAltered"
                required label="From (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.current_employer.employment_start_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'date_format:MM/DD/YYYY'" data-vv-name="work_history.current_employer.employment_end_date"
                data-vv-as="Date" :error="errors.first('work_history.current_employer.employment_end_date')" @focus="formAltered"
                label="To (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.current_employer.employment_end_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-6">
              <div class="form-check form-check-inline">
                <label class="form-check-label">May we contact them?</label>
              </div>
              <br>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.current_employer.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.current_employer.may_we_contact_them"
                  id="may_we_contact_them_yes" value="Yes">
                <label class="form-check-label" for="may_we_contact_them_yes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.current_employer.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.current_employer.may_we_contact_them"
                  id="may_we_contact_them_no" value="No">
                <label class="form-check-label" for="may_we_contact_them_no">No</label>
              </div>
              <span v-if="errors.has('work_history.current_employer.may_we_contact_them')" class="label-font text-danger">
                &nbsp;{{ errors.first('work_history.current_employer.may_we_contact_them') }}</span>
            </div>
          </div>
        </section>
        <!-- END Current employer -->
        <hr>
        <!-- Begin Previous Employer 1 -->
        <span class="h4">Previous Employer</span>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" v-model="work_history.previous_employer_1.add_employer"
            @focus="formAltered">
          <label class="form-check-label" for="exampleCheck1">Add Previous Employer</label>
        </div>
        <section v-if="work_history.previous_employer_1.add_employer">
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_1.employer" data-vv-as="Employer"
                :error="errors.first('work_history.previous_employer_1.employer')" @focus="formAltered" required label="Employer"
                v-model="work_history.previous_employer_1.employer"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.previous_employer_1.city"
                data-vv-as="City" :error="errors.first('work_history.previous_employer_1.city')" @focus="formAltered"
                required label="City" v-model="work_history.previous_employer_1.city"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <label for="state">State*</label>
              <select v-validate="'required'" data-vv-name="work_history.previous_employer_1.state" data-vv-as="State"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.previous_employer_1.state">
                <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.previous_employer_1.state')" class="text-danger invalid-feedback"
                style="display: block;">
                {{ errors.first('work_history.previous_employer_1.state') }}
              </div>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="work_history.previous_employer_1.phone"
                data-vv-as="Phone" :error="errors.first('work_history.previous_employer_1.phone')" @focus="formAltered"
                required label="Phone" v-model="work_history.previous_employer_1.phone"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_1.title_position"
                data-vv-as="Title Position" :error="errors.first('work_history.previous_employer_1.title_position')"
                @focus="formAltered" required label="Your title or position" v-model="work_history.previous_employer_1.title_position"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.previous_employer_1.supervisor_name"
                data-vv-as="Supervisor Name" :error="errors.first('work_history.previous_employer_1.supervisor_name')"
                @focus="formAltered" required label="Supervisor/Manager Name" v-model="work_history.previous_employer_1.supervisor_name"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_1.supervisor_title"
                data-vv-as="Supervisor Title" :error="errors.first('work_history.previous_employer_1.supervisor_title')"
                @focus="formAltered" required label="Supervisor/Manager Title" v-model="work_history.previous_employer_1.supervisor_title"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5 col-lg-7">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_1.reason_for_leaving"
                data-vv-as="Reason for Leaving" :error="errors.first('work_history.previous_employer_1.reason_for_leaving')"
                @focus="formAltered" required label="Reason for leaving" v-model="work_history.previous_employer_1.reason_for_leaving"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_1.salary" data-vv-as="Salary"
                :error="errors.first('work_history.previous_employer_1.salary')" @focus="formAltered" required label="Salary"
                v-model="work_history.previous_employer_1.salary"></base-input>
            </div>
            <div class="col-12 col-md-3 col-lg-2">
              <label for="state">specify</label>
              <select v-validate="'required'" data-vv-name="work_history.previous_employer_1.salary_type" data-vv-as="Salary Type"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.previous_employer_1.salary_type">
                <option v-for="i in salary_types" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.previous_employer_1.salary_type')" class="text-danger invalid-feedback"
                style="display: block;">
                {{ errors.first('work_history.previous_employer_1.salary_type') }}
              </div>
            </div>
          </div>
          <h6>Dates for Employment</h6>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="work_history.previous_employer_1.employment_start_date"
                data-vv-as="Date" :error="errors.first('work_history.previous_employer_1.employment_start_date')"
                @focus="formAltered" required label="From (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.previous_employer_1.employment_start_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="work_history.previous_employer_1.employment_end_date"
                data-vv-as="Date" :error="errors.first('work_history.previous_employer_1.employment_end_date')" @focus="formAltered"
                required label="To (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.previous_employer_1.employment_end_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-6">
              <div class="form-check form-check-inline">
                <label class="form-check-label">May we contact them?</label>
              </div>
              <br>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.previous_employer_1.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.previous_employer_1.may_we_contact_them"
                  id="may_we_contact_them_yes" value="Yes">
                <label class="form-check-label" for="may_we_contact_them_yes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.previous_employer_1.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.previous_employer_1.may_we_contact_them"
                  id="may_we_contact_them_no" value="No">
                <label class="form-check-label" for="may_we_contact_them_no">No</label>
              </div>
              <span v-if="errors.has('work_history.previous_employer_1.may_we_contact_them')" class="label-font text-danger">
                &nbsp;{{ errors.first('work_history.current_employer.may_we_contact_them') }}</span>
            </div>
          </div>
        </section>

        <!-- END Previous Employer 1 -->
        <hr>
        <!-- Begin Previous Employer 2 -->
        <span class="h4">Previous Employer</span>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" v-model="work_history.previous_employer_2.add_employer"
            @focus="formAltered">
          <label class="form-check-label" for="exampleCheck1">Add Previous Employer</label>
        </div>
        <section v-if="work_history.previous_employer_2.add_employer">
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_2.employer" data-vv-as="Employer"
                :error="errors.first('work_history.previous_employer_2.employer')" @focus="formAltered" required label="Employer"
                v-model="work_history.previous_employer_2.employer"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.previous_employer_2.city"
                data-vv-as="City" :error="errors.first('work_history.previous_employer_2.city')" @focus="formAltered"
                required label="City" v-model="work_history.previous_employer_2.city"></base-input>
            </div>
            <div class="col-12 col-md-4">
              <label for="state">State*</label>
              <select v-validate="'required'" data-vv-name="work_history.previous_employer_2.state" data-vv-as="State"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.previous_employer_2.state">
                <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.previous_employer_2.state')" class="text-danger invalid-feedback"
                style="display: block;">
                {{ errors.first('work_history.previous_employer_2.state') }}
              </div>
            </div>
            <div class="col-12 col-md-4">
              <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="work_history.previous_employer_2.phone"
                data-vv-as="Phone" :error="errors.first('work_history.previous_employer_2.phone')" @focus="formAltered"
                required label="Phone" v-model="work_history.previous_employer_2.phone"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_2.title_position"
                data-vv-as="Title Position" :error="errors.first('work_history.previous_employer_2.title_position')"
                @focus="formAltered" required label="Your title or position" v-model="work_history.previous_employer_2.title_position"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="work_history.previous_employer_2.supervisor_name"
                data-vv-as="Supervisor Name" :error="errors.first('work_history.previous_employer_2.supervisor_name')"
                @focus="formAltered" required label="Supervisor/Manager Name" v-model="work_history.previous_employer_2.supervisor_name"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_2.supervisor_title"
                data-vv-as="Supervisor Title" :error="errors.first('work_history.previous_employer_2.supervisor_title')"
                @focus="formAltered" required label="Supervisor/Manager Title" v-model="work_history.previous_employer_2.supervisor_title"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-5 col-lg-7">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_2.reason_for_leaving"
                data-vv-as="Reason for Leaving" :error="errors.first('work_history.previous_employer_2.reason_for_leaving')"
                @focus="formAltered" required label="Reason for leaving" v-model="work_history.previous_employer_2.reason_for_leaving"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required'" data-vv-name="work_history.previous_employer_2.salary" data-vv-as="Salary"
                :error="errors.first('work_history.previous_employer_2.salary')" @focus="formAltered" required label="Salary"
                v-model="work_history.previous_employer_2.salary"></base-input>
            </div>
            <div class="col-12 col-md-3 col-lg-2">
              <label for="state">specify</label>
              <select v-validate="'required'" data-vv-name="work_history.previous_employer_2.salary_type" data-vv-as="Salary Type"
                @focus="formAltered" required name="" id="" class="form-control" v-model="work_history.previous_employer_2.salary_type">
                <option v-for="i in salary_types" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('work_history.previous_employer_2.salary_type')" class="text-danger invalid-feedback"
                style="display: block;">
                {{ errors.first('work_history.previous_employer_2.salary_type') }}
              </div>
            </div>
          </div>
          <h6>Dates for Employment</h6>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="work_history.previous_employer_2.employment_start_date"
                data-vv-as="Date" :error="errors.first('work_history.previous_employer_2.employment_start_date')"
                @focus="formAltered" required label="From (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.previous_employer_2.employment_start_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="work_history.previous_employer_2.employment_end_date"
                data-vv-as="Date" :error="errors.first('work_history.previous_employer_2.employment_end_date')" @focus="formAltered"
                required label="To (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="work_history.previous_employer_2.employment_end_date"></base-input>
            </div>
            <div class="col-12 col-md-4 col-lg-6">
              <div class="form-check form-check-inline">
                <label class="form-check-label">May we contact them?</label>
              </div>
              <br>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.previous_employer_2.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.previous_employer_2.may_we_contact_them"
                  id="may_we_contact_them_yes" value="Yes">
                <label class="form-check-label" for="may_we_contact_them_yes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="work_history.previous_employer_2.may_we_contact_them"
                  data-vv-as="Contact" @focus="formAltered" required class="form-check-input" type="radio" v-model="work_history.previous_employer_2.may_we_contact_them"
                  id="may_we_contact_them_no" value="No">
                <label class="form-check-label" for="may_we_contact_them_no">No</label>
              </div>
              <span v-if="errors.has('work_history.previous_employer_2.may_we_contact_them')" class="label-font text-danger">
                &nbsp;{{ errors.first('work_history.current_employer.may_we_contact_them') }}</span>
            </div>
          </div>
        </section>
        <!-- END Previous Employer 2 -->

        <div class="row mt-3">
          <div class="col-12">
            <base-button v-if="work_history.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
            <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
          </div>
        </div>
      </section>
    </card>
  </div>
</template>

<script>
  import {
    states
  } from '@/views/components/resources.js'
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    name: 'work_history',
    props: {
      clear: String
    },
    data: () => ({
      salary_types: ['hour', 'week', 'month', 'year'],
      states,
      show_work_history: false,
      show_current_employer: false,
      show_previous_employer_1: false,
      show_previous_employer_2: false,
      work_history: {
        saved: false,
        section_complete: false,
        current_employer: {
          employer: '',
          city: '',
          state: '',
          phone: '',
          title_position: '',
          supervisor_name: '',
          supervisor_title: '',
          reason_for_leaving: '',
          salary: '',
          salary_type: 'hour',
          employment_start_date: '',
          employment_end_date: '',
          may_we_contact_them: ''
        },
        previous_employer_1: {
          add_employer: false,
          employer: '',
          city: '',
          state: '',
          phone: '',
          title_position: '',
          supervisor_name: '',
          supervisor_title: '',
          reason_for_leaving: '',
          salary: '',
          salary_type: '',
          employment_start_date: '',
          employment_end_date: '',
          may_we_contact_them: ''
        },
        previous_employer_2: {
          add_employer: false,
          employer: '',
          city: '',
          state: '',
          phone: '',
          title_position: '',
          supervisor_name: '',
          supervisor_title: '',
          reason_for_leaving: '',
          salary: '',
          salary_type: '',
          employment_start_date: '',
          employment_end_date: '',
          may_we_contact_them: ''
        },
      }
    }),
    watch: {
      'work_history.previous_employer_1.add_employer': function () {
        if (!this.work_history.previous_employer_1.add_employer) {
          this.work_history.previous_employer_1 = {
            add_employer: false,
            employer: '',
            city: '',
            state: '',
            phone: '',
            title_position: '',
            supervisor_name: '',
            supervisor_title: '',
            reason_for_leaving: '',
            salary: '',
            salary_type: '',
            employment_start_date: '',
            employment_end_date: '',
            may_we_contact_them: ''
          }
        }
      },
      clear() {
        this.work_history = {
          saved: false,
          section_complete: false,
          current_employer: {
            employer: '',
            city: '',
            state: '',
            phone: '',
            title_position: '',
            supervisor_name: '',
            supervisor_title: '',
            reason_for_leaving: '',
            salary: '',
            salary_type: 'hour',
            employment_start_date: '',
            employment_end_date: '',
            may_we_contact_them: ''
          },
          previous_employer_1: {
            add_employer: false,
            employer: '',
            city: '',
            state: '',
            phone: '',
            title_position: '',
            supervisor_name: '',
            supervisor_title: '',
            reason_for_leaving: '',
            salary: '',
            salary_type: '',
            employment_start_date: '',
            employment_end_date: '',
            may_we_contact_them: ''
          },
          previous_employer_2: {
            add_employer: false,
            employer: '',
            city: '',
            state: '',
            phone: '',
            title_position: '',
            supervisor_name: '',
            supervisor_title: '',
            reason_for_leaving: '',
            salary: '',
            salary_type: '',
            employment_start_date: '',
            employment_end_date: '',
            may_we_contact_them: ''
          },
        }
        this.$validator.reset()
      }
    },
    methods: {
      formAltered() {
        this.work_history.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.work_history')) {
          this.work_history = JSON.parse(localStorage.getItem('healthViewApplication.work_history'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.work_history.saved = true
            this.work_history.section_complete = false
            localStorage.setItem('healthViewApplication.work_history', JSON.stringify(this.work_history))
          } else {
            this.work_history.saved = true
            this.work_history.section_complete = true
            localStorage.setItem('healthViewApplication.work_history', JSON.stringify(this.work_history))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>

<style>

</style>