<template>
	<div>
		<HeroSmall :page_title="page_title" :leadline="leadline" />
		<div class="container py-lg ">
					<base-alert class="mt-3" type="success lead" v-if="show_completed">
              <strong>Success!</strong> Your Application has been submitted!
          </base-alert>

					<section v-else>
						<div class="row my-3">
							<div class="col-12 text-center">
								<a class="btn btn-primary btn-lg" href="/HealthViewHomeHealth_Employment_Application.pdf" target="_blank" rel="noopener" >Download PDF Application</a>
								<base-button size="lg" type="danger" @click="clearMyData">Clear My Data</base-button>
							</div>
						</div>

						<PositionPreferences :job_title="job_title" :job_key="job_key" :clear="clear_data" />
						<PersonalData :clear="clear_data" />
						<Education :clear="clear_data" />
						<Licenses :clear="clear_data" />
						<References :clear="clear_data" />
						<WorkHistory :clear="clear_data" />
						<Agreement @submitted="showCompleted()" :clear="clear_data" />

					</section>
		</div>
	</div>
</template>
<script>
	import HeroSmall from '@/views/components/HeroSmall.vue'
	// import ApplicationDataNotice from '@/views/components/employment/ApplicationDataNotice.vue'
	import PositionPreferences from '@/views/Employment/components/PositionPreferences.vue'
	import PersonalData from '@/views/Employment/components/PersonalData.vue'
	import Education from '@/views/Employment/components/Education.vue'
	import Licenses from '@/views/Employment/components/Licenses.vue'
	import References from '@/views/Employment/components/References.vue'
	import WorkHistory from '@/views/Employment/components/WorkHistory.vue'
	import Agreement from '@/views/Employment/components/Agreement.vue'
	export default {
		name: 'employment',
		components: {
      HeroSmall,
      // ApplicationDataNotice,
      PositionPreferences,
      PersonalData,
      Education,
      Licenses,
      References,
      WorkHistory,
      Agreement,
		},
		data: () => ({
			page_title: 'Join our Team',
			leadline: 'HealthView Home Health &amp; Hospice',
			clear_data: '',
			show_completed: false,
			job_title: '',
			job_key: ''
		}),
		methods: {
			goTo(path) {
				this.$router.push({
					path: path
				})
			},
			scrollFix: function (hash) {
				setTimeout(() => $('html, body').animate({
					scrollTop: $(hash).offset().top
				}, 1000), 1)
			},
			clearMyData(){
				localStorage.removeItem('healthViewApplication.education')
				localStorage.removeItem('healthViewApplication.licenses')
				localStorage.removeItem('healthViewApplication.personal_data')
				localStorage.removeItem('healthViewApplication.position_preferences')
				localStorage.removeItem('healthViewApplication.references')
				localStorage.removeItem('healthViewApplication.work_history')
				// this.clear_data = true //call clearDataEvent()
				this.clearDataEvent()
			},
			clearDataEvent(){
				this.clear_data = 'yes'
				setTimeout(() => {
					this.clear_data = ''
				},100)
			},
			showCompleted(){
				this.show_completed = true
				this.clearMyData()
			}
		},
		created(){
			this.job_title = this.$route.query.job_title
			this.job_key = this.$route.query.job_key
		}
	};
</script>
