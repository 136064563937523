<template>
  <div id="agreement">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_agreement = !show_agreement">
          <i v-if="show_agreement" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Agreement
          </span>
        </a>
      </div>
      <!-- <div>
        <span v-if="show_agreement.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div> -->
    </nav>
    <card class="mb-5" shadow>
      <!-- <pre v-html="references"></pre> -->
      <section v-if="show_agreement">
        <div class="row">
          <div class="col-12">
            <p>In the event of my employment to a position with Healthview Home Health & Hospice (NC), I will comply
              with all rules and regulations of this Company. I understand that the Company reserves the right to
              require me to submit to a test for the presence of drugs in my system prior to employment and at any time
              during my employment, to the extent permitted by law. I also understand that any offer of employment may
              be contingent upon the passing of a physical examination and a test for the presence of alcohol in my
              system, performed by a doctor selected by the company. Further, I understand that at any time after I am
              hired, the Company may require me to submit to a physical examination and alcohol test, to the extent
              permitted by law. I consent to the disclosure of the results of any physical examination and related
              tests to the Company. I also understand that I may be required to take other such tests such as
              personality and honest tests prior to employment and during my employment. I understand that should I
              decline to sign this consent or decline to take any of the above tests, my application for employment may
              be rejected or my employment may be terminated. I understand that bonding may be a condition of hire. If
              it is, I will be so advised either before or after hiring and a bond application will have to be
              completed.</p>
            <p>I understand that the Company may investigate my driving record and my criminal record and that an
              investigative consumer report may be prepared whereby information is obtained through personal interviews
              with my neighbors, friends, personal references, and other with whom I am acquainted. This inquiry
              includes information as to my character, general reputation, personal characteristics and mode of living.
              I understand that I have the right to make a written inquiry within a reasonable period of time to
              receive additional detailed information about the nature and scope of this investigation. I further
              understand that the Company may contact my previous employers and I authorize those employers to
              disclosed to the Company all records and information pertinent to my employment with them. In addition to
              authorizing the release of any information regarding my employment, I hereby fully waived any rights or
              claims I have or may have against my former employers, their agents, employees and representatives, as
              well as other individuals who release information to the Company, and release them from any and all
              liability claims, or damages that may directly or in indirectly result from the use, disclosure, or
              release of any such information by any person or party, whether such information is favorable or
              unfavorable to me. I authorize the persons named herein as personal references to provide the Company
              with any pertinent information that may have regarding me.</p>
            <p>I hereby state that all information that I provided on this application or any other documents filled
              out in connection with my employment, and in any interview is true and correct. I have withheld nothing
              that would, if disclosed, affect the application unfavorably. I understand that if I am employed and any
              such information is later found to false or incomplete in any respect, I may be dismissed. I understand
              if selected for hire, it will be necessary for me to provide satisfactory evidence of my identity and
              legal authority to work in the United States, and that federal immigration laws require me to complete
              and I-9 form in this regard.</p>
            <p>If hired, I agreed as follows: My employment and compensation is terminable at-will, is for no definite
              period, and my employment compensation may be terminated by the Company(employer) at any time and for any
              reason whatsoever, with or without good cause at the option of either the Company or me. No implied,
              oral, or written agreements contrary to the express language of this agreement are valid unless they are
              in writing and signed by the President of the Company(or majority owner or owners if Company is not a
              corporation). No supervisor or representative of the Company, other than the President(or majority owner
              or owners if Company is not a corporation), has any authority to make any agreements contrary to the
              foregoing. This agreement is the entire agreement between the Company and the employee regarding the
              rights of the Company or employee to terminated employment with or without good cause, and this agreement
              takes the place of all prior and contemporaneous agreements, representations, and understanding of the
              employee and the Company.</p>
            <p>If you have any questions regarding this Statement, please ask a Company representative before signing.
              I hereby acknowledge that I have read the above Statements and understand the same.</p>
            <p>DO NOT SIGN UNTIL YOU HAVE READ AND UNDERSTOOD THE ABOVE STATEMENT AND AGREEMENT.</p>
          </div>
          <div class="col-12">
            <hr>
          </div>
          <div class="col-1">
            <p class="lead text-center">
              <input type="checkbox" v-model="application.agreement.read_agreement" v-validate="'required'"
                data-vv-name="agreement.read_agreement" data-vv-as="Read Agreement" required>
              <i v-if="errors.has('agreement.read_agreement')" class="d-block fas fa-exclamation-triangle text-warning"></i>
            </p>
          </div>
          <div class="col-11">
            <div v-if="errors.has('agreement.read_agreement')" class="text-danger invalid-feedback" style="display: block;">
              {{ errors.first('agreement.read_agreement') }}
            </div>
            <p class="lead">I certify that all of this information I have provided on this application is true and
              accurate. I understand this application will be considered active for a maximum of thirty (60) days and
              that if I wish to be considered for employment after that time, I must reapply.</p>
          </div>
        </div>
        <hr>
        <div class="row mb-3">
          <div class="col-12">
            <div class="card">
              <div class="card-header dense">
                Upload a resume
              </div>
              <div class="card-body">
                <input type="file" accept=".doc,.docx,application/msword,.pdf,application/pdf" @change="detectFiles($event.target.files)">
                <button v-if="progress_upload == 100" class="btn btn-secondary">Uploaded</button>
                <button v-else :class="['btn', enable_upload_button ? 'btn-primary': 'btn-dark']" type="button" @click="uploadFile"
                  :disabled="!enable_upload_button">Upload Resume</button>
                <div v-if="progress_upload > 0 && progress_upload < 100 " class="progress mt-1">
                  <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :style="{ width: progress_upload + '%'}"
                    :aria-valuenow="progress_upload" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <base-input v-model="application.agreement.name" v-validate="'required|alpha_spaces'" data-vv-name="application.agreement.name"
              data-vv-as="Name" :error="errors.first('application.agreement.name')" required label="Full Name of Applicant"></base-input>
          </div>
          <div class="col-12 col-md-6">
            <base-input v-model="application.agreement.date" v-validate="'required|date_format:MM/DD/YYYY'"
              data-vv-name="application.agreement.date" data-vv-as="date" :error="errors.first('application.agreement.date')"
              required placeholder="MM/DD/YYYY" label="Date (MM/DD/YYYY)"></base-input>
          </div>
          <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
            <!-- <pre v-html="application.agreement.user_info"></pre>         -->
            <base-button size="lg" block @click="loadApplicationData" type="primary">Submit Application</base-button>

            <!-- <pre v-html="sections_complete"></pre> -->
            <!-- <pre v-html="application"></pre> -->
          </div>
        </div>
      </section>


    </card>
  </div>
</template>

<script>
  import {
    employmentApplicationsRef,
    employmentApplicationsListRef,
    storageRef
  } from '@/firebase'
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    name: 'agreement',
    data: () => ({
      application_submitted: false,
      show_agreement: true,
      user_info_error: '',
      application_complete: false,
      sections_complete: {
        education: true,
        licenses: true,
        personal_data: true,
        position_preferences: true,
        references: true,
        work_history: true,
        agreement: true,
      },
      application: {
        agreement: {
          read_agreement: false,
          section_complete: false,
          date: '',
          name: '',
        },
        resume: {
          resume_file: '',
          orginal_file_name: ''
        }
      },
      enable_upload_button: false,
      progress_upload: 0,
      file: File,
      uploadTask: '',
    }),
    props: {
      clear: String
    },
    watch: {
      clear() {
        this.application = {
          agreement: {
            read_agreement: false,
            section_complete: false,
            date: '',
            name: '',
          },
          resume: {
            resume_file: '',
            orginal_file_name: ''
          }
        }
        this.$validator.reset()
      },
      uploadTask: function () {
        this.uploadTask.on('state_changed', sp => {
            this.progress_upload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
          },
          null,
          () => {})
      }
    },
    methods: {
      detectFiles(file_list) {
        this.progress_upload = 0
        this.enable_upload_button = true
        Array.from(Array(file_list.length).keys()).map(x => {
          this.file = file_list[x]
        })
      },
      uploadFile() {
        let file = this.file
        let timestamp = Date.now()
        let resume_file = timestamp + '_' + file.name

        this.application.resume.resume_file = resume_file
        this.application.resume.orginal_file_name = file.name

        this.uploadTask = storageRef.child(`resumes/${resume_file}`).put(file)

      },
      loadApplicationData() {
        this.$validator.validate().then(result => {
          if (result) {
            this.sections_complete.agreement = true

            if (localStorage.getItem('healthViewApplication.education')) {
              this.application.education = JSON.parse(localStorage.getItem('healthViewApplication.education'))
              this.sections_complete.education = this.application.education.section_complete
            }
            if (localStorage.getItem('healthViewApplication.licenses')) {
              this.application.licenses = JSON.parse(localStorage.getItem('healthViewApplication.licenses'))
              this.sections_complete.licenses = this.application.licenses.section_complete
            }
            if (localStorage.getItem('healthViewApplication.personal_data')) {
              this.application.personal_data = JSON.parse(localStorage.getItem(
                'healthViewApplication.personal_data'))
              this.sections_complete.personal_data = this.application.personal_data.section_complete
            }
            if (localStorage.getItem('healthViewApplication.position_preferences')) {
              this.application.position_preferences = JSON.parse(localStorage.getItem(
                'healthViewApplication.position_preferences'))
              this.sections_complete.position_preferences = this.application.position_preferences.section_complete
            }
            if (localStorage.getItem('healthViewApplication.references')) {
              this.application.references = JSON.parse(localStorage.getItem('healthViewApplication.references'))
              this.sections_complete.references = this.application.references.section_complete
            }
            if (localStorage.getItem('healthViewApplication.work_history')) {
              this.application.work_history = JSON.parse(localStorage.getItem('healthViewApplication.work_history'))
              this.sections_complete.work_history = this.application.work_history.section_complete
            }
            this.checkApplication()
          }
        })
      },
      checkApplication() {
        function testAllProperties(obj, val) {
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (obj[key] !== val) {
                return false;
              }
            }
          }
          return true;
        }

        if (testAllProperties(this.sections_complete, true)) {
          this.submitToFirebase()
        }
      },
      submitToFirebase() {
        let fbObj = this.application

        let timestamp = Date.now()

        let friendly_timestamp = new Date(timestamp).toString()

        fbObj.datestamp = friendly_timestamp
        // TODO: Push for key with user name to employmentApplicationsList. Then push entire object

        let quickObj = {
          first_name: this.application.personal_data.first_name,
          last_name: this.application.personal_data.last_name,
          email: this.application.position_preferences.email,
          city: this.application.personal_data.city,
          state: this.application.personal_data.state,
          daytime_phone: this.application.personal_data.daytime_phone,
          position: this.application.position_preferences.position
        }

        let key = ''

        //push to a short list for getting an application list
        employmentApplicationsListRef.push(quickObj).then((snap) => {
          key = snap.key
          employmentApplicationsRef.child(key).set(fbObj).then(() => {
            this.$emit('submitted')
            this.clearMyData()
            this.application_submitted = true
          })
        })
        // .then(

        // )


      },

      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.agreement')) {
          this.agreement = JSON.parse(localStorage.getItem('healthViewApplication.agreement'))
        }
      },
      saveSection() {
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.agreement.saved = true
            this.agreement.section_complete = false
            localStorage.setItem('healthViewApplication.agreement', JSON.stringify(this.agreement))
          } else {
            this.agreement.saved = true
            this.agreement.section_complete = true
            localStorage.setItem('healthViewApplication.agreement', JSON.stringify(this.agreement))
          }
        })
      },
      clearMyData() {
        this.application = {
          agreement: {
            read_agreement: false,
          },
        }
        localStorage.removeItem('healthViewApplication.education')
        localStorage.removeItem('healthViewApplication.licenses')
        localStorage.removeItem('healthViewApplication.personal_data')
        localStorage.removeItem('healthViewApplication.position_preferences')
        localStorage.removeItem('healthViewApplication.references')
        localStorage.removeItem('healthViewApplication.work_history')
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>
