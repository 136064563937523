<template>
  <div id="education">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_education = !show_education">
          <i v-if="show_education" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Education
          </span>
        </a>
      </div>
      <div>
        <span v-if="education.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_education">
        <!-- Card interior -->
        <section>
          <h4>High School</h4>
          <div class="row">
            <div class="col-12">
              <base-input v-validate="'required'" data-vv-name="education.hs_school_name" data-vv-as="High School Name"
                :error="errors.first('education.hs_school_name')" @focus="formAltered" required v-model="education.hs_school_name"
                label="School Name"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <base-input v-validate="'required|alpha_spaces'" data-vv-name="education.hs_city" data-vv-as="City"
                :error="errors.first('education.hs_city')" @focus="formAltered" required v-model="education.hs_city"
                label="City"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <label for="state">State</label>
              <select v-validate="'required'" data-vv-name="education.hs_state" data-vv-as="State" @focus="formAltered"
                required name="" id="" class="form-control" v-model="education.hs_state">
                <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
              </select>
              <div v-if="errors.has('education.hs_state')" class="text-danger invalid-feedback" style="display: block;">
                {{ errors.first('education.hs_state') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-check form-check-inline">
                <label class="form-check-label">Diploma</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="education.hs_diploma" data-vv-as="Diploma" @focus="formAltered"
                  required class="form-check-input" type="radio" v-model="education.hs_diploma" id="diploma_yes" value="Yes">
                <label class="form-check-label" for="diploma_yes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-validate="'required'" data-vv-name="education.hs_diploma" data-vv-as="Diploma" @focus="formAltered"
                  required class="form-check-input" type="radio" v-model="education.hs_diploma" id="diploma_no" value="No">
                <label class="form-check-label" for="diploma_no">No</label>
              </div>
              <base-input v-validate="education.hs_diploma == 'No' ? 'required|numeric' : ''" data-vv-name="education.hs_years_completed"
                data-vv-as="Years Completed" :error="errors.first('education.hs_years_completed')" @focus="formAltered"
                required v-if="education.hs_diploma === 'No'" type="number" v-model="education.hs_years_completed"
                label="Number of years completed"></base-input>
            </div>
            <div class="col-12 col-md-6">
              <br v-if="education.hs_diploma ==='No'" />
              <base-input v-validate="'required'" data-vv-name="education.hs_area_of_study" data-vv-as="Area of Study"
                :error="errors.first('education.hs_area_of_study')" @focus="formAltered" required v-model="education.hs_area_of_study"
                label="Major of area of study"></base-input>
            </div>
          </div>
        </section>
        <hr>
        <section>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" v-model="education.cut1">
            <label class="form-check-label" for="exampleCheck1">Additional Education</label>
          </div>
          <section v-if="education.cut1">
            <div class="row">
              <div class="col-12">
                <h4>College, University, Technical Trade School or Other</h4>
                <base-input v-validate="education.cut1 ? 'required' : ''" data-vv-name="education.cut1_school_name"
                  data-vv-as="College/University Name" :error="errors.first('education.cut1_school_name')" @focus="formAltered"
                  required v-model="education.cut1_school_name" label="School Name"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input v-validate="education.cut1 ? 'required|alpha_spaces' : ''" data-vv-name="education.cut1_city"
                  data-vv-as="City" :error="errors.first('education.cut1_city')" @focus="formAltered" required v-model="education.cut1_city"
                  label="City"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="state">State</label>
                <select v-validate="education.cut1 ? 'required': ''" data-vv-name="education.cuts1_state" data-vv-as="State"
                  @focus="formAltered" required name="" id="" class="form-control" v-model="education.cut1_state">
                  <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
                </select>
                <div v-if="errors.has('education.cuts1_state')" class="text-danger invalid-feedback" style="display: block;">
                  {{ errors.first('education.cuts1_state') }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">Diploma</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-validate="education.cut1 ? 'required': ''" data-vv-name="education.cuts1_diploma" data-vv-as="Diploma"
                    @focus="formAltered" required class="form-check-input" type="radio" v-model="education.cut1_diploma"
                    id="diploma_yes" value="Yes">
                  <label class="form-check-label" for="diploma_yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-validate="education.cut1 ? 'required': ''" data-vv-name="education.cuts1_diploma" data-vv-as="Diploma"
                    @focus="formAltered" required class="form-check-input" type="radio" v-model="education.cut1_diploma"
                    id="diploma_no" value="No">
                  <label class="form-check-label" for="diploma_no">No</label>
                </div>
                <base-input v-validate="education.cuts1_diploma == 'No' ? 'required|numeric' : ''" data-vv-name="education.hs_years_completed"
                  data-vv-as="Years Completed" :error="errors.first('education.cut1_years_completed')" @focus="formAltered"
                  required v-if="education.cut1_diploma === 'No'" v-model="education.cut1_years_completed" type="number"
                  label="Number of years completed"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <br v-if="education.cut1_diploma ==='No'" />
                <base-input v-validate="education.cut1 ? 'required' : ''" data-vv-name="education.cut1_area_of_study"
                  data-vv-as="Area of Study" :error="errors.first('education.cut1_area_of_study')" @focus="formAltered"
                  required v-model="education.cut1_area_of_study" label="Major of area of study"></base-input>
              </div>
            </div>
          </section>
          <hr>
        </section>

        <section v-if="education.cut1">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" v-model="education.cut2">
            <label class="form-check-label" for="exampleCheck1">Additional Education</label>
          </div>
          <section v-if="education.cut2">
            <h4>College, University, Technical Trade School or Other</h4>
            <div class="row">
              <div class="col-12">
                <base-input v-model="education.cut2_school_name" label="School Name"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input v-model="education.cut2_city" label="City"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="state">State</label>
                <select name="" id="" class="form-control" v-model="education.cut2_state">
                  <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">Diploma</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" v-model="education.cut2_diploma" id="diploma_yes" value="Yes">
                  <label class="form-check-label" for="diploma_yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" v-model="education.cut2_diploma" id="diploma_no" value="No">
                  <label class="form-check-label" for="diploma_no">No</label>
                </div>
                <base-input v-if="education.cut2_diploma === 'No'" v-model="education.cut2_years_completed" type="number"
                  +label="Number of years completed"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <br v-if="education.cut2_diploma ==='No'" />
                <base-input v-model="education.cut2_area_of_study" label="Major of area of study"></base-input>
              </div>
            </div>

          </section>
        </section>
        <base-button v-if="education.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
        <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
      </section>
    </card>
  </div>

</template>

<script>
  import {
    states
  } from '@/views/components/resources.js'
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    name: 'education',
    props: {
      clear: String
    },
    watch: {
      clear() {
        this.education = {
          saved: false,
          section_complete: false,
          hs_school_name: '',
          hs_city: '',
          hs_state: '',
          hs_diploma: '',
          hs_years_completed: '',
          hs_area_of_study: '',
          cut1: false,
          cut1_school_name: '',
          cut1_city: '',
          cut1_state: '',
          cut1_diploma: '',
          cut1_years_completed: '',
          cut1_area_of_study: '',
          cut2: false,
          cut2_school_name: '',
          cut2_city: '',
          cut2_state: '',
          cut2_diploma: '',
          cut2_years_completed: '',
          cut2_area_of_study: '',
        }
        this.$validator.reset()
      }
    },
    data: () => ({
      states,
      show_education: false,
      education: {
        saved: false,
        section_complete: false,
        hs_school_name: '',
        hs_city: '',
        hs_state: '',
        hs_diploma: '',
        hs_years_completed: '',
        hs_area_of_study: '',
        cut1: false,
        cut1_school_name: '',
        cut1_city: '',
        cut1_state: '',
        cut1_diploma: '',
        cut1_years_completed: '',
        cut1_area_of_study: '',
        cut2: false,
        cut2_school_name: '',
        cut2_city: '',
        cut2_state: '',
        cut2_diploma: '',
        cut2_years_completed: '',
        cut2_area_of_study: '',
      }
    }),
    methods: {
      formAltered() {
        this.education.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.education')) {
          this.education = JSON.parse(localStorage.getItem('healthViewApplication.education'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.education.saved = true
            this.education.section_complete = false
            localStorage.setItem('healthViewApplication.education', JSON.stringify(this.education))
          } else {
            this.education.saved = true
            this.education.section_complete = true
            localStorage.setItem('healthViewApplication.education', JSON.stringify(this.education))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>

<style>

</style>