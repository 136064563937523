<template>
  <div id="personal_data">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_personal_data = !show_personal_data">
          <i v-if="show_personal_data" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Personal Data
          </span>
        </a>
      </div>
      <div>
        <span v-if="personal_data.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_personal_data">
        <div class="row">
          <div class="col-12 col-md-4">
            <base-input v-validate="'required|alpha'" data-vv-name="personal_data.first_name" data-vv-as="First Name"
              :error="errors.first('personal_data.first_name')" @focus="formAltered" required label="First Name"
              v-model="personal_data.first_name"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="'alpha'" data-vv-name="personal_data.middle_name" data-vv-as="Middle Name" :error="errors.first('personal_data.middle_name')"
              @focus="formAltered" label="Middle Name" v-model="personal_data.middle_name"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="'required|alpha'" data-vv-name="personal_data.last_name" data-vv-as="Last Name"
              :error="errors.first('personal_data.last_name')" @focus="formAltered" required label="Last Name" v-model="personal_data.last_name"></base-input>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <base-input v-validate="'required'" data-vv-name="personal_data.street_address" data-vv-as="Street Address"
              :error="errors.first('personal_data.street_address')" @focus="formAltered" required label="Street Address"
              v-model="personal_data.street_address"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="'required|alpha_spaces'" data-vv-name="personal_data.city" data-vv-as="City" :error="errors.first('personal_data.city')"
              @focus="formAltered" required label="City" v-model="personal_data.city"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <label for="state">State</label>
            <select v-validate="'required'" data-vv-name="personal_data.state" data-vv-as="State" @focus="formAltered"
              required name="" id="" class="form-control" v-model="personal_data.state">
              <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
            </select>
            <div v-if="errors.has('personal_data.state')" class="text-danger invalid-feedback" style="display: block;">
              {{ errors.first('personal_data.state') }}
            </div>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="'required|numeric|length:5'" data-vv-name="personal_data.zip" data-vv-as="Zip Code"
              :error="errors.first('personal_data.zip')" @focus="formAltered" required label="Zip Code" placeholder="5 digits"
              v-model="personal_data.zip"></base-input>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-4">
            <!-- <input v-validate="{ required: true, regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" > -->
            <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="personal_data.home_phone"
              data-vv-as="Home Phone" :error="errors.first('personal_data.home_phone')" @focus="formAltered" required
              label="Home Phone" v-model="personal_data.home_phone"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="{min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="personal_data.cell_phone"
              data-vv-as="Cell Phone" :error="errors.first('personal_data.cell_phone')" @focus="formAltered" label="Cell Phone"
              v-model="personal_data.cell_phone"></base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }" data-vv-name="personal_data.daytime_phone"
              data-vv-as="Daytime Phone" :error="errors.first('personal_data.daytime_phone')" @focus="formAltered"
              required label="Best Daytime Phone" v-model="personal_data.daytime_phone"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <label class="form-check-label">If hired, can you furnish proof you are over 18 years of age?</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.over_18_proof" data-vv-as="Over 18" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.over_18_proof" id="over_18_yes"
                value="Yes">
              <label class="form-check-label" for="over_18_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.over_18_proof" data-vv-as="Over 18" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.over_18_proof" id="over_18_no"
                value="No">
              <label class="form-check-label" for="over_18_no">No</label>
              <span v-if="errors.has('personal_data.over_18_proof')" class="label-font text-danger"> &nbsp;{{
                errors.first('personal_data.over_18_proof') }}</span>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-check form-check-inline">
              <label class="form-check-label">Are you a Veteran?</label>
            </div>
            <br />
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.veteran" data-vv-as="Veteran" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.veteran" id="veteran_yes" value="Yes">
              <label class="form-check-label" for="veteran_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.veteran" data-vv-as="Veteran" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.veteran" id="veteran_no" value="No">
              <label class="form-check-label" for="veteran_no">No</label>
              <!-- TODO: Add watcher to clear dependent required fields -->
            </div>
            <span v-if="errors.has('personal_data.veteran')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.veteran') }}</span>
          </div>
          <div v-if="personal_data.veteran === 'Yes'" class="col-12 col-md-4">
            <base-input v-validate="personal_data.veteran == 'Yes' ? 'required' : ''" data-vv-name="personal_data.veteran_branch"
              data-vv-as="Military Branch" :error="errors.first('personal_data.veteran_branch')" @focus="formAltered"
              required label="If so, what branch?" v-model="personal_data.veteran_branch"></base-input>
          </div>
          <div v-if="personal_data.veteran === 'Yes'" class="col-12 col-md-4">
            <base-input v-validate="personal_data.veteran == 'Yes' ? 'required|date_format:MM/DD/YYYY' : ''"
              data-vv-name="personal_data.veteran_discharge_date" data-vv-as="Discharge Date" :error="errors.first('personal_data.veteran_discharge_date')"
              @focus="formAltered" required label="Discharge Date (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="personal_data.veteran_discharge_date"></base-input>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <label>Have you ever been convicted of or pleaded no contest to child or adult abuse, neglect or
              mistreatment or any felony or misdemeanor crime?</label>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.convicted_felony_misdemeanor" data-vv-as="Felony / Misdemeanor"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.convicted_felony_misdemeanor"
                id="convicted_felony_misdemeanor_yes" value="Yes">
              <label class="form-check-label" for="convicted_felony_misdemeanor_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.convicted_felony_misdemeanor" data-vv-as="Felony / Misdemeanor"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.convicted_felony_misdemeanor"
                id="convicted_felony_misdemeanor_no" value="No">
              <label class="form-check-label" for="convicted_felony_misdemeanor_no">No</label>
            </div>

          </div>
          <div v-if="personal_data.convicted_felony_misdemeanor === 'Yes'" class="col-12">
            <p class="label-font">If yes, please explain offense and disposition.</p>
            <p v-if="errors.has('personal_data.conviction_explain')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.conviction_explain') }}</p>
            <p class="font-italic small">Note: A conviction will not necessarily result in the denial of employment.
              Rather, such factors as date, convictions, and rehabilitation will be considered (This includes the entry
              of a plea of guilty or nolo contender)</p>
            <textarea v-validate="personal_data.convicted_felony_misdemeanor == 'Yes' ? 'required' : ''" data-vv-name="personal_data.conviction_explain"
              data-vv-as="Description of offense and disposition" @focus="formAltered" required v-model="personal_data.conviction_explain"
              class="form-control" rows="4" placeholder="Please explain here ..."></textarea>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-check form-check-inline">
              <label class="form-check-label">Are you able to work overtime?</label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.overtime" data-vv-as="Overtime" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.overtime" id="overtime_yes" value="Yes">
              <label class="form-check-label" for="overtime_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.overtime" data-vv-as="Overtime" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.overtime" id="overtime_no" value="No">
              <label class="form-check-label" for="overtime_no">No</label>
              <span v-if="errors.has('personal_data.overtime')" class="label-font text-danger"> &nbsp;{{
                errors.first('personal_data.overtime') }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-check form-check-inline">
              <label class="form-check-label">Are you related to any employee that is employed with this company?</label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.related_to_other_employee" data-vv-as="Related to other employee"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.related_to_other_employee"
                id="related_to_other_employee_yes" value="Yes">
              <label class="form-check-label" for="related_to_other_employee_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.related_to_other_employee" data-vv-as="Related to other employee"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.related_to_other_employee"
                id="related_to_other_employee_no" value="No">
              <label class="form-check-label" for="related_to_other_employee_no">No</label>
            </div>
            <span v-if="errors.has('personal_data.related_to_other_employee')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.related_to_other_employee') }}</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <label class="form-check-label">Can you perform the essential functions of this position you are applying
                for?</label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.perform_job" data-vv-as="Perform job" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.perform_job" id="perform_job_yes"
                value="Yes">
              <label class="form-check-label" for="perform_job_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.perform_job" data-vv-as="Perform Job" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="personal_data.perform_job" id="perform_job_no"
                value="No">
              <label class="form-check-label" for="perform_job_no">No</label>
            </div>
            <span v-if="errors.has('personal_data.perform_job')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.perform_job') }}</span>
          </div>
          <div class="col-12" v-if="personal_data.perform_job === 'No'">
            <label for="perform_job_notes">If no, please explain.</label>
            <p v-if="errors.has('personal_data.perform_job_notes')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.perform_job_notes') }}</p>
            <textarea v-validate="personal_data.perform_job == 'Yes' ? 'required' : ''" data-vv-name="personal_data.perform_job_notes"
              data-vv-as="Job Limitations Explanation" @focus="formAltered" required v-model="personal_data.perform_job_notes"
              class="form-control" rows="4" placeholder="Please explain here ..."></textarea>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-check form-check-inline">
              <label class="form-check-label">Are you legally eligible for employment in the US?</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.legally_eligible" data-vv-as="Eligible for Employment"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.legally_eligible"
                id="legally_eligible_yes" value="Yes">
              <label class="form-check-label" for="legally_eligible_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="personal_data.legally_eligible" data-vv-as="Eligible for Employment"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="personal_data.legally_eligible"
                id="legally_eligible_no" value="No">
              <label class="form-check-label" for="legally_eligible_no">No</label>
            </div>
            <span v-if="errors.has('personal_data.legally_eligible')" class="label-font text-danger"> &nbsp;{{
              errors.first('personal_data.legally_eligible') }}</span>
          </div>
          <div class="col-12 col-md-6">
            <p class="label-font">Identity and employment eligibility of all new hires will be verified as required by
              the Immigration Reform and Control Act of 1986).</p>
          </div>
          <base-button v-if="personal_data.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
          <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
        </div>
      </section>
    </card>
  </div>
</template>

<script>
  import {
    states
  } from '@/views/components/resources.js'
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    name: 'personal_data',
    props: {
      clear: String
    },
    watch: {
      clear() {
        this.personal_data = {
          saved: false,
          section_complete: false,
          todays_date: '',
          first_name: '',
          middle_name: '',
          last_name: '',
          street_address: '',
          city: '',
          state: '',
          zip: '',
          home_phone: '',
          cell_phone: '',
          daytime_phone: '',
          over_18_proof: '',
          veteran: '',
          veteran_branch: '',
          veteran_discharge_date: '',
          convicted_felony_misdemeanor: '',
          conviction_explain: '',
          overtime: '',
          related_to_other_employee: '',
          perform_job: '',
          perform_job_notes: '',
          legally_eligible: ''
        }
        this.$validator.reset()
      }
    },
    data: () => ({
      show_personal_data: false,
      personal_data: {
        saved: false,
        section_complete: false,
        todays_date: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        street_address: '',
        city: '',
        state: '',
        zip: '',
        home_phone: '',
        cell_phone: '',
        daytime_phone: '',
        over_18_proof: '',
        veteran: '',
        veteran_branch: '',
        veteran_discharge_date: '',
        convicted_felony_misdemeanor: '',
        conviction_explain: '',
        overtime: '',
        related_to_other_employee: '',
        perform_job: '',
        perform_job_notes: '',
        legally_eligible: ''

      },
      states,
    }),
    methods: {
      formAltered() {
        this.personal_data.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.personal_data')) {
          this.personal_data = JSON.parse(localStorage.getItem('healthViewApplication.personal_data'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.personal_data.saved = true
            this.personal_data.section_complete = false
            localStorage.setItem('healthViewApplication.personal_data', JSON.stringify(this.personal_data))
          } else {
            this.personal_data.saved = true
            this.personal_data.section_complete = true
            localStorage.setItem('healthViewApplication.personal_data', JSON.stringify(this.personal_data))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>

<style>
  .label-font {
    font-size: 0.875rem;
  }
</style>