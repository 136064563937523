<template>
  <div id="references">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_references = !show_references">
          <i v-if="show_references" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            References
          </span>
        </a>
      </div>
      <div>
        <span v-if="references.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_references">
        <div class="row">
          <div class="col-12">
            <h4>Professional Reference</h4>
          </div>
          <div class="col-12 col-md-6">
            <base-input v-validate="'required|alpha_spaces'" data-vv-name="references.professional_reference_1.name"
              data-vv-as="Name" :error="errors.first('references.professional_reference_1.name')" @focus="formAltered"
              required label="Name" v-model="references.professional_reference_1.name"></base-input>
            <base-input v-validate="'required'" data-vv-name="references.professional_reference_1.company" data-vv-as="Company"
              :error="errors.first('references.professional_reference_1.company')" @focus="formAltered" required label="Company"
              v-model="references.professional_reference_1.company"></base-input>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-6">
                <base-input v-validate="'required'" data-vv-name="references.professional_reference_1.title" data-vv-as="Title"
                  :error="errors.first('references.professional_reference_1.title')" @focus="formAltered" required
                  label="Title" v-model="references.professional_reference_1.title"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }"
                  data-vv-name="references.professional_reference_1.phone" data-vv-as="Phone" :error="errors.first('references.professional_reference_1.phone')"
                  @focus="formAltered" required label="Phone" v-model="references.professional_reference_1.phone"></base-input>
              </div>
              <div class="col-12">
                <base-input v-validate="'required'" data-vv-name="references.professional_reference_1.relationship"
                  data-vv-as="Relationship" :error="errors.first('references.professional_reference_1.relationship')"
                  @focus="formAltered" required label="Professional Relationship" v-model="references.professional_reference_1.relationship"></base-input>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <h4>Professional Reference</h4>
          </div>
          <div class="col-12 col-md-6">
            <base-input v-validate="'required|alpha_spaces'" data-vv-name="references.professional_reference_2.name"
              data-vv-as="Name" :error="errors.first('references.professional_reference_2.name')" @focus="formAltered"
              required label="Name" v-model="references.professional_reference_2.name"></base-input>
            <base-input v-validate="'required'" data-vv-name="references.professional_reference_2.company" data-vv-as="Company"
              :error="errors.first('references.professional_reference_2.company')" @focus="formAltered" required label="Company"
              v-model="references.professional_reference_2.company"></base-input>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-6">
                <base-input v-validate="'required'" data-vv-name="references.professional_reference_2.title" data-vv-as="Title"
                  :error="errors.first('references.professional_reference_2.title')" @focus="formAltered" required
                  label="Title" v-model="references.professional_reference_2.title"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }"
                  data-vv-name="references.professional_reference_2.phone" data-vv-as="Phone" :error="errors.first('references.professional_reference_2.phone')"
                  @focus="formAltered" required label="Phone" v-model="references.professional_reference_2.phone"></base-input>
              </div>
              <div class="col-12">
                <base-input v-validate="'required'" data-vv-name="references.professional_reference_2.relationship"
                  data-vv-as="Relationship" :error="errors.first('references.professional_reference_2.relationship')"
                  @focus="formAltered" required label="Professional Relationship" v-model="references.professional_reference_2.relationship"></base-input>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <h4>Personal Reference</h4>
          </div>
          <div class="col-12 col-md-6">
            <base-input v-validate="'required|alpha_spaces'" data-vv-name="references.personal_reference.name"
              data-vv-as="Name" :error="errors.first('references.personal_reference.name')" @focus="formAltered"
              required label="Name" v-model="references.personal_reference.name"></base-input>
            <base-input v-validate="'required'" data-vv-name="references.personal_reference.company" data-vv-as="Company"
              :error="errors.first('references.personal_reference.company')" @focus="formAltered" required label="Company"
              v-model="references.personal_reference.company"></base-input>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-6">
                <base-input v-validate="'required'" data-vv-name="references.personal_reference.title" data-vv-as="Title"
                  :error="errors.first('references.personal_reference.title')" @focus="formAltered" required label="Title"
                  v-model="references.personal_reference.title"></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input v-validate="{required: true, min: 10 ,regex: /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/ }"
                  data-vv-name="references.personal_reference.phone" data-vv-as="Phone" :error="errors.first('references.personal_reference.phone')"
                  @focus="formAltered" required label="Phone" v-model="references.personal_reference.phone"></base-input>
              </div>
              <div class="col-12">
                <base-input v-validate="'required'" data-vv-name="references.personal_reference.relationship"
                  data-vv-as="Relationship" :error="errors.first('references.personal_reference.relationship')" @focus="formAltered"
                  required label="Personal Relationship" v-model="references.personal_reference.relationship"></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <base-button v-if="references.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
            <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
          </div>
        </div>
      </section>
    </card>
  </div>
</template>

<script>
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    name: 'references',
    props: {
      clear: String
    },
    watch: {
      clear() {
        this.references = {
          saved: false,
          section_complete: false,
          professional_reference_1: {
            name: '',
            company: '',
            title: '',
            phone: '',
            relationship: ''
          },
          professional_reference_2: {
            name: '',
            company: '',
            title: '',
            phone: '',
            relationship: ''
          },
          personal_reference: {
            name: '',
            company: '',
            title: '',
            phone: '',
            relationship: ''
          }
        }
        this.$validator.reset()
      }
    },
    data: () => ({
      show_references: false,
      references: {
        saved: false,
        section_complete: false,
        professional_reference_1: {
          name: '',
          company: '',
          title: '',
          phone: '',
          relationship: ''
        },
        professional_reference_2: {
          name: '',
          company: '',
          title: '',
          phone: '',
          relationship: ''
        },
        personal_reference: {
          name: '',
          company: '',
          title: '',
          phone: '',
          relationship: ''
        }
      }
    }),
    methods: {
      formAltered() {
        this.references.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.references')) {
          this.references = JSON.parse(localStorage.getItem('healthViewApplication.references'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.references.saved = true
            this.references.section_complete = false
            localStorage.setItem('healthViewApplication.references', JSON.stringify(this.references))
          } else {
            this.references.saved = true
            this.references.section_complete = true
            localStorage.setItem('healthViewApplication.references', JSON.stringify(this.references))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>

<style>

</style>