<template>
  <div id="licenses">
    <nav class="navbar navbar-dark bg-primary justify-content-between">
      <div>
        <a class="navbar-brand text-white" @click="show_licenses = !show_licenses">
          <i v-if="show_licenses" class="fa fa-chevron-down fa-2x"></i>
          <i v-else class="fa fa-chevron-up fa-2x"></i>
          <span class="h5 text-white ml-3">
            Licenses &amp; Certifications
          </span>
        </a>
      </div>
      <div>
        <span v-if="licenses.section_complete">
          <span class="text-white">Completed </span>
          <i class="fas fa-check-circle text-success"></i>
        </span>
        <span v-else class="text-white">
          Not Complete
          <i class="fas fa-exclamation-triangle text-white"></i>
        </span>
      </div>
    </nav>
    <card class="mb-5" shadow>
      <section v-if="show_licenses">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="card mb-3">
              <div class="card-header dense">
                <div class="form-group form-check">
                  <label class="form-check-label" for="exampleCheck1">License/Certification</label>
                </div>
              </div>
              <div class="card-body">
                <base-input v-validate="'required'" data-vv-name="licenses.license1.type" data-vv-as="Type" :error="errors.first('licenses.license1.type')"
                  @focus="formAltered" required label="Type" v-model="licenses.license1.type"></base-input>
                <base-input v-validate="'required'" data-vv-name="licenses.license1.issued_by" data-vv-as="Organization / State"
                  :error="errors.first('licenses.license1.issued_by')" @focus="formAltered" required label="Organization or State Issued:"
                  v-model="licenses.license1.issued_by"></base-input>
                <base-input v-validate="'required|date_format:MM/DD/YYYY'" data-vv-name="licenses.license1.date_issued"
                  data-vv-as="Date Issued" :error="errors.first('licenses.license1.date_issued')" @focus="formAltered"
                  required label="Date Issued (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="licenses.license1.date_issued"></base-input>
                <base-input v-validate="'required'" data-vv-name="licenses.license1.number" data-vv-as="Number" :error="errors.first('licenses.license1.number')"
                  @focus="formAltered" required label="Number" v-model="licenses.license1.number"></base-input>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card">
              <div class="card-header dense">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" v-model="licenses.license2.add_license">
                  <label class="form-check-label" for="exampleCheck1">Additional License/Certification</label>
                </div>
              </div>
              <div class="card-body">
                <base-input :disabled="!licenses.license2.add_license" v-validate="licenses.license2.add_license ? 'required' : ''"
                  data-vv-name="licenses.license2.type" data-vv-as="Type" :error="errors.first('licenses.license2.type')"
                  @focus="formAltered" required label="Type" v-model="licenses.license2.type"></base-input>
                <base-input :disabled="!licenses.license2.add_license" v-validate="licenses.license2.add_license ? 'required' : ''"
                  data-vv-name="licenses.license2.issued_by" data-vv-as="Organization / State" :error="errors.first('licenses.license2.issued_by')"
                  @focus="formAltered" required label="Organization or State Issued:" v-model="licenses.license2.issued_by"></base-input>
                <base-input :disabled="!licenses.license2.add_license" v-validate="licenses.license2.add_license ? 'required|date_format:MM/DD/YYYY' : ''"
                  data-vv-name="licenses.license2.date_issued" data-vv-as="Date Issued" :error="errors.first('licenses.license2.date_issued')"
                  @focus="formAltered" required label="Date Issued (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="licenses.license2.date_issued"></base-input>
                <base-input :disabled="!licenses.license2.add_license" v-validate="licenses.license2.add_license ? 'required' : ''"
                  data-vv-name="licenses.license2.number" data-vv-as="Number" :error="errors.first('licenses.license2.number')"
                  @focus="formAltered" required label="Number" v-model="licenses.license2.number"></base-input>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card">
              <div class="card-header dense">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" v-model="licenses.license3.add_license">
                  <label class="form-check-label" for="exampleCheck1">Additional License/Certification</label>
                </div>
              </div>
              <div class="card-body">
                <base-input :disabled="!licenses.license3.add_license" v-validate="licenses.license3.add_license ? 'required' : ''"
                  data-vv-name="licenses.license3.type" data-vv-as="Type" :error="errors.first('licenses.license3.type')"
                  @focus="formAltered" required label="Type" v-model="licenses.license3.type"></base-input>
                <base-input :disabled="!licenses.license3.add_license" v-validate="licenses.license3.add_license ? 'required' : ''"
                  data-vv-name="licenses.license3.issued_by" data-vv-as="Organization / State" :error="errors.first('licenses.license3.issued_by')"
                  @focus="formAltered" required label="Organization or State Issued:" v-model="licenses.license3.issued_by"></base-input>
                <base-input :disabled="!licenses.license3.add_license" v-validate="licenses.license3.add_license ? 'required|date_format:MM/DD/YYYY' : ''"
                  data-vv-name="licenses.license3.date_issued" data-vv-as="Date Issued" :error="errors.first('licenses.license3.date_issued')"
                  @focus="formAltered" required label="Date Issued (MM/DD/YYYY)" placeholder="MM/DD/YYYY" v-model="licenses.license3.date_issued"></base-input>
                <base-input :disabled="!licenses.license3.add_license" v-validate="licenses.license3.add_license ? 'required' : ''"
                  data-vv-name="licenses.license3.number" data-vv-as="Number" :error="errors.first('licenses.license3.number')"
                  @focus="formAltered" required label="Number" v-model="licenses.license3.number"></base-input>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <label class="mr-2">Have your professional license ever been suspended for any reason?</label>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.suspension" data-vv-as="Suspended License" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="licenses.suspension" id="suspension_yes" value="Yes">
              <label class="form-check-label" for="suspension_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.suspension" data-vv-as="Suspended License" @focus="formAltered"
                required class="form-check-input" type="radio" v-model="licenses.suspension" id="suspension_no" value="No">
              <label class="form-check-label" for="suspension_no">No</label>
            </div>
            <span v-if="errors.has('licenses.suspension')" class="label-font text-danger"> &nbsp;{{
              errors.first('licenses.suspension') }}</span>
          </div>
          <div v-if="licenses.suspension === 'Yes'" class="col-12">
            <p class="label-font">If yes, please explain reason.</p>
            <p v-if="errors.has('licenses.suspension_notes')" class="label-font text-danger"> &nbsp;{{
              errors.first('licenses.suspension_notes') }}</p>
            <textarea v-validate="licenses.suspension == 'Yes' ? 'required' : ''" data-vv-name="licenses.suspension_notes"
              data-vv-as="Suspension Notes" @focus="formAltered" required v-model="licenses.suspensions_notes" class="form-control"
              rows="4" placeholder="Please explain here ..."></textarea>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6">
            <label class="mr-2">Do you have a Valid Driver's License?</label>
            <br>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.drivers_license" data-vv-as="Driver's License"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="licenses.drivers_license"
                id="drivers_license_yes" value="Yes">
              <label class="form-check-label" for="drivers_license_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.drivers_license" data-vv-as="Driver's License"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="licenses.drivers_license"
                id="drivers_license_no" value="No">
              <label class="form-check-label" for="drivers_license_no">No</label>
              <span v-if="errors.has('licenses.drivers_license')" class="label-font text-danger"> &nbsp;{{
                errors.first('licenses.drivers_license') }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label for="state">State Issued</label>
            <select v-validate="licenses.drivers_license == 'Yes' ? 'required' : ''" :disabled="licenses.drivers_license == 'No' || licenses.drivers_license == ''"
              data-vv-name="licenses.drivers_license_state" data-vv-as="State" @focus="formAltered" required name="" id=""
              class="form-control" v-model="licenses.drivers_license_state">
              <option v-for="i in states" :value="i" :key="i" v-text="i"></option>
            </select>
            <div v-if="errors.has('licenses.drivers_license_state')" class="text-danger invalid-feedback" style="display: block;">
              {{ errors.first('licenses.drivers_license_state') }}
            </div>
            <base-input v-validate="licenses.drivers_license == 'Yes' ? 'required' : ''" :disabled="licenses.drivers_license == 'No' || licenses.drivers_license == ''"
              data-vv-name="licenses.drivers_license_number" :error="errors.first('licenses.drivers_license_number')"
              data-vv-as="License #" @focus="formAltered" required v-model="licenses.drivers_license_number" label="License #"></base-input>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <label class="mr-2">Have you been convicted of any moving violations in the past five year?</label>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.moving_violations" data-vv-as="Moving Violations"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="licenses.moving_violations"
                id="moving_violations_yes" value="Yes">
              <label class="form-check-label" for="moving_violations_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-validate="'required'" data-vv-name="licenses.moving_violations" data-vv-as="Moving Violations"
                @focus="formAltered" required class="form-check-input" type="radio" v-model="licenses.moving_violations"
                id="moving_violations_no" value="No">
              <label class="form-check-label" for="moving_violations_no">No</label>
            </div>
            <span v-if="errors.has('licenses.moving_violations')" class="label-font text-danger"> &nbsp;{{
              errors.first('licenses.moving_violations') }}</span>
          </div>
          <div v-if="licenses.moving_violations === 'Yes'" class="col-12">
            <p class="label-font">If yes, please explain.</p>
            <p v-if="errors.has('licenses.moving_violations_notes')" class="label-font text-danger"> &nbsp;{{
              errors.first('licenses.moving_violations_notes') }}</p>
            <textarea v-validate="licenses.moving_violations == 'Yes' ? 'required' : ''" data-vv-name="licenses.moving_violations_notes"
              data-vv-as="Moving Violations Notes" @focus="formAltered" required v-model="licenses.moving_violations_notes"
              class="form-control" rows="4" placeholder="Please explain here ..."></textarea>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <base-button v-if="licenses.saved" outline disabled type="success" @click="saveSection()">Saved</base-button>
            <base-button v-else outline type="info" @click="saveSection()">Save Section</base-button>
          </div>
        </div>
      </section>
    </card>
  </div>
</template>

<script>
  import {
    states
  } from '@/views/components/resources.js'
  export default {
    name: 'licenses',
    props: {
      clear: String
    },
    data: () => ({
      states,
      show_licenses: false,
      licenses: {
        saved: false,
        section_complete: false,
        license1: {
          type: '',
          issued_by: '',
          date_issued: '',
          number: ''
        },
        license2: {
          add_license: false,
          type: '',
          issued_by: '',
          date_issued: '',
          number: ''
        },
        license3: {
          add_license: false,
          type: '',
          issued_by: '',
          date_issued: '',
          number: ''
        },
        suspension: '',
        suspensions_notes: '',
        drivers_license: '',
        drivers_license_state: '',
        drivers_license_number: '',
        moving_violations: '',
        moving_violations_notes: '',
      }
    }),
    watch: {
      'licenses.license2.add_license': function () {
        if (!this.licenses.license2.add_license) {
          this.licenses.license2 = {
            add_license: false,
            type: '',
            issued_by: '',
            date_issued: '',
            number: ''
          }
        }
      },
      'licenses.license3.add_license': function () {
        if (!this.licenses.license3.add_license) {
          this.licenses.license3 = {
            add_license: false,
            type: '',
            issued_by: '',
            date_issued: '',
            number: ''
          }
        }
      },
            clear() {
        this.licenses = {
          saved: false,
          section_complete: false,
          license1: {
            type: '',
            issued_by: '',
            date_issued: '',
            number: ''
          },
          license2: {
            add_license: false,
            type: '',
            issued_by: '',
            date_issued: '',
            number: ''
          },
          license3: {
            add_license: false,
            type: '',
            issued_by: '',
            date_issued: '',
            number: ''
          },
          suspension: '',
          suspensions_notes: '',
          drivers_license: '',
          drivers_license_state: '',
          drivers_license_number: '',
          moving_violations: '',
          moving_violations_notes: '',
        }
        this.$validator.reset()
      }
    },
    methods: {
      formAltered() {
        this.licenses.saved = false
        // This doesn't change localStorage saved from true to false
      },
      loadSavedVersion() {
        if (localStorage.getItem('healthViewApplication.licenses')) {
          this.licenses = JSON.parse(localStorage.getItem('healthViewApplication.licenses'))
        }
      },
      saveSection() {
        // this.$validator.validateAll()
        this.$validator.validate().then(result => {
          if (!result) {
            // do stuff if not valid.
            this.licenses.saved = true
            this.licenses.section_complete = false
            localStorage.setItem('healthViewApplication.licenses', JSON.stringify(this.licenses))
          } else {
            this.licenses.saved = true
            this.licenses.section_complete = true
            localStorage.setItem('healthViewApplication.licenses', JSON.stringify(this.licenses))
          }
        })
      }
    },
    created() {
      this.loadSavedVersion()
    }
  }
</script>

<style>

</style>